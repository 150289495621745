import ContentWrapper from "base-components/ContentWrapper";
import AdBanner from "./AdBanner";
import PinnedArticle from "./PinnedArticle";
import styles from "./PinnedArticles.module.scss";

const PinnedArticlesSkeleton = () => (
  <ContentWrapper className={styles.pinnedArticles}>
    <section className={styles.articles}>
      <PinnedArticle size="large" />
      <div className={styles.secondaryArticles}>
        <PinnedArticle />
        <PinnedArticle />
      </div>
    </section>
    <AdBanner className={styles.adBanner} />
  </ContentWrapper>
);

export default PinnedArticlesSkeleton;
