import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { compact, isEmpty } from "lodash";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Await from "base-components/Await";
import Button from "base-components/Button";
import ContentWrapper from "base-components/ContentWrapper";
import IconText from "base-components/IconText";
import ArticlePreview from "components/ArticlePreview";
import Sidebar from "components/Sidebar/Sidebar";
import { usePaginationState } from "contexts/Paginator";
import {
  ARTICLES_QUERY,
  ARTICLE_CATEGORY_QUERY,
  ARTICLE_SUBCATEGORIES_QUERY,
} from "graphql/article";
import {
  ArticleCategory,
  ArticleCategoryVariables,
  ArticleOrderByInput,
  Articles,
  ArticleSubcategories,
  ArticlesVariables,
  Articles_articles_data,
  SortOrder,
} from "types/graphql";
import styles from "./index.module.scss";

const ARTICLES_DEFAULT_DISPLAY_COUNT = 10;

const composeArticlesQueryString = (params = {}) => ({
  pathname: "/articles",
  query: params,
});

export type ArticlePageProps = {
  defaultSubcategoryId?: number;
};

const ArticlesPage = ({ defaultSubcategoryId }: ArticlePageProps) => {
  const router = useRouter();

  const {
    category: categoryQueryId,
    subcategory: subcategoryQueryId,
    tag,
    tags,
    orderBy: rawOrderBy,
  } = router.query;

  const [limit, setLimit] = useState(ARTICLES_DEFAULT_DISPLAY_COUNT);

  const categoryId = useMemo(
    () => Number.parseInt((categoryQueryId as string) ?? "0", 10),
    [categoryQueryId]
  );

  const categoryParam = useMemo(
    () => (categoryId ? { category: categoryId } : {}),
    [categoryId]
  );

  const orderBy: ArticleOrderByInput[] | null = useMemo(() => {
    try {
      return JSON.parse(rawOrderBy as string);
    } catch (error) {
      return [
        {
          publishedAt: SortOrder.desc,
        },
      ];
    }
  }, [rawOrderBy]);

  const subcategoryId = useMemo(
    () =>
      Number.parseInt(
        (subcategoryQueryId as string) ?? defaultSubcategoryId ?? "0",
        10
      ),
    [subcategoryQueryId, defaultSubcategoryId]
  );

  const {
    loading: loadingSubcategories,
    error: errorSubcategories,
    data: subcategoriesData,
  } = useQuery<ArticleSubcategories>(ARTICLE_SUBCATEGORIES_QUERY);

  const {
    loading: loadingCategory,
    error: errorCategory,
    data: categoryData,
  } = useQuery<ArticleCategory, ArticleCategoryVariables>(
    ARTICLE_CATEGORY_QUERY,
    {
      variables: {
        where: {
          id: {
            equals: categoryId || -1,
          },
        },
      },
    }
  );

  const [[articles, { totalCount }], setArticles] =
    usePaginationState<Articles_articles_data>();

  const compactedCategoryIds = compact([categoryId]);
  const compactedSubcategoryIds = compact([subcategoryId]);

  const compactedTags = compact(
    [tag as string, ...[(tags as string[]) || []]].flat()
  );

  const tagsInput = isEmpty(compactedTags) ? null : compactedTags;

  const { loading: loadingArticles, error: errorArticles } = useQuery<
    Articles,
    ArticlesVariables
  >(ARTICLES_QUERY, {
    variables: {
      orderBy,
      where: {
        published: true,
        tagsSearch: tagsInput,
        categories: {
          some: {
            id: {
              in: isEmpty(compactedCategoryIds) ? null : compactedCategoryIds,
            },
          },
        },
        subcategories: {
          some: {
            id: {
              in: isEmpty(compactedSubcategoryIds)
                ? null
                : compactedSubcategoryIds,
            },
          },
        },
      },
      limit,
      offset: 0,
    },
    onCompleted: ({ articles: { data, pageInfo } }) => {
      setArticles([data, pageInfo]);
    },
  });

  const intl = useIntl();

  const loading = loadingSubcategories || loadingCategory || loadingArticles;
  const error = errorSubcategories || errorCategory || errorArticles;

  const subcategories = subcategoriesData?.articleSubcategories?.data ?? [];
  const category = categoryData?.articleCategory;

  return (
    <>
      {(categoryQueryId || subcategoryQueryId) && (
        <Head>
          <title>{intl.formatMessage({ defaultMessage: "Articles" })}</title>
        </Head>
      )}
      <ContentWrapper contentAs="main" contentClassName={styles.articles}>
        <section className={styles.articlesContent}>
          <IconText as="h2" icon="newspaper">
            {`${intl.formatMessage({ defaultMessage: "Articles" })} ${
              category?.title ? `- ${category.title}` : ""
            }`}
          </IconText>
          <Await loading={loading} error={error}>
            <header className={styles.articleSubcategories}>
              {subcategories.slice(0, 2).map((subcategory) => (
                <Link
                  key={`subcategory-${subcategory.id}`}
                  href={composeArticlesQueryString({
                    ...categoryParam,
                    subcategory: subcategory.id,
                  })}
                >
                  <a
                    className={classNames(
                      {
                        [styles.selected]: subcategoryId === subcategory.id,
                      },
                      styles.articleSubcategory
                    )}
                  >
                    {subcategory.title}
                  </a>
                </Link>
              ))}
              <Link href={composeArticlesQueryString(categoryParam)}>
                <a
                  className={classNames(
                    { [styles.selected]: !subcategoryId },
                    styles.articleSubcategory
                  )}
                >
                  <FormattedMessage defaultMessage="Other" />
                </a>
              </Link>
            </header>
            <ul className={styles.articleList}>
              {articles.map((article) => (
                <li key={`article-${article.id}`}>
                  <ArticlePreview article={article} detailed />
                </li>
              ))}
            </ul>
            <section className={styles.articlesButton}>
              {limit > ARTICLES_DEFAULT_DISPLAY_COUNT && (
                <Button
                  backgroundColor="cool-gray-600"
                  onClick={() => {
                    setLimit((currentLimit) =>
                      Math.max(
                        currentLimit - ARTICLES_DEFAULT_DISPLAY_COUNT,
                        ARTICLES_DEFAULT_DISPLAY_COUNT
                      )
                    );
                  }}
                  htmlType="button"
                >
                  <FormattedMessage defaultMessage="Show less" />
                </Button>
              )}
              {totalCount > limit && (
                <Button
                  backgroundColor="cool-gray-600"
                  onClick={() => {
                    setLimit(
                      (currentLimit) =>
                        currentLimit + ARTICLES_DEFAULT_DISPLAY_COUNT
                    );
                  }}
                  htmlType="button"
                >
                  <FormattedMessage defaultMessage="Show more" />
                </Button>
              )}
            </section>
          </Await>
        </section>
        <Sidebar
          articleTemplates={[
            {
              template: "most-read",
            },
          ]}
          twitchChannel="ygamessk"
        />
      </ContentWrapper>
    </>
  );
};

export default ArticlesPage;
