import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { kebabCase } from "lodash";
import moment from "moment";
import Link from "next/link";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LinesEllipsis from "react-lines-ellipsis";
import Await from "base-components/Await";
import Button from "base-components/Button";
import ContentWrapper from "base-components/ContentWrapper";
import EventsCarousel from "base-components/EventsCarousel/EventsCarousel";
import { getFilePath } from "common/utils";
import { HOME_EVENTS_QUERY } from "graphql/home";
import {
  HomeEvents_homeEvents_data,
  HomeEvents as foo,
  HomeEventsVariables,
} from "types/graphql";
import styles from "./Events.module.scss";
import EventsSkeleton from "./EventsSkeleton";

const Events = () => {
  const [homeEvents, setHomeEvents] = useState<HomeEvents_homeEvents_data[]>(
    []
  );
  const { loading, error } = useQuery<foo, HomeEventsVariables>(
    HOME_EVENTS_QUERY,
    {
      onCompleted: (data) => {
        setHomeEvents(data.homeEvents?.data);
      },
      variables: {
        limit: 5,
      },
    }
  );
  const intl = useIntl();

  return (
    <Await loading={loading} error={error} fallback={<EventsSkeleton />}>
      <ContentWrapper
        backgroundColor="cool-gray-950"
        contentClassName={styles.events}
      >
        <EventsCarousel
          name={intl.formatMessage({ defaultMessage: "Events" })}
          heading={
            <h2 className={styles.eventsTitle}>
              <FontAwesomeIcon icon="calendar-alt" />
              <FormattedMessage defaultMessage="Upcoming events" />
            </h2>
          }
          headerChildren={
            <div className={styles.eventsButtons}>
              <Link href="/projects">
                <a>
                  <Button backgroundColor="cool-gray-600" htmlType="button">
                    <FormattedMessage defaultMessage="All events" />
                  </Button>
                </a>
              </Link>
              <Link href="/tournaments">
                <a>
                  <Button backgroundColor="cool-gray-600" htmlType="button">
                    <FormattedMessage defaultMessage="All tournaments" />
                  </Button>
                </a>
              </Link>
            </div>
          }
        >
          {homeEvents.map((event) => (
            <Link key={`event-${event.title}`} href={event.url ?? "/"}>
              <a
                key={`event-${kebabCase(event.title || "")}`}
                className={styles.event}
              >
                <img
                  className={styles.eventImage}
                  alt={event.title || ""}
                  src={getFilePath(event.image)}
                />
                <p className={styles.eventTitle}>
                  <LinesEllipsis text={event.title || ""} maxLine={2} />
                </p>
                <article className={styles.eventMeta}>
                  <time className={styles.eventMetaInfo} dateTime={event.date}>
                    <FontAwesomeIcon icon="calendar-alt" />
                    {moment(event.date).format("DD.MM.YYYY")}
                  </time>
                  <address className={styles.eventMetaInfo}>
                    <FontAwesomeIcon icon="location-dot" />
                    {event.place}
                  </address>
                </article>
              </a>
            </Link>
          ))}
        </EventsCarousel>
      </ContentWrapper>
    </Await>
  );
};

export default Events;
