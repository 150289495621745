import PropTypes from "prop-types";
import React from "react";

const Calendar = ({ className = "" }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 12 14"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.85417 7.83337H2.8125C2.64063 7.83337 2.5 7.69275 2.5 7.52087V6.47921C2.5 6.30733 2.64063 6.16671 2.8125 6.16671H3.85417C4.02604 6.16671 4.16667 6.30733 4.16667 6.47921V7.52087C4.16667 7.69275 4.02604 7.83337 3.85417 7.83337ZM6.66667 7.52087V6.47921C6.66667 6.30733 6.52604 6.16671 6.35417 6.16671H5.3125C5.14063 6.16671 5 6.30733 5 6.47921V7.52087C5 7.69275 5.14063 7.83337 5.3125 7.83337H6.35417C6.52604 7.83337 6.66667 7.69275 6.66667 7.52087ZM9.16667 7.52087V6.47921C9.16667 6.30733 9.02604 6.16671 8.85417 6.16671H7.8125C7.64063 6.16671 7.5 6.30733 7.5 6.47921V7.52087C7.5 7.69275 7.64063 7.83337 7.8125 7.83337H8.85417C9.02604 7.83337 9.16667 7.69275 9.16667 7.52087ZM6.66667 10.0209V8.97921C6.66667 8.80733 6.52604 8.66671 6.35417 8.66671H5.3125C5.14063 8.66671 5 8.80733 5 8.97921V10.0209C5 10.1927 5.14063 10.3334 5.3125 10.3334H6.35417C6.52604 10.3334 6.66667 10.1927 6.66667 10.0209ZM4.16667 10.0209V8.97921C4.16667 8.80733 4.02604 8.66671 3.85417 8.66671H2.8125C2.64063 8.66671 2.5 8.80733 2.5 8.97921V10.0209C2.5 10.1927 2.64063 10.3334 2.8125 10.3334H3.85417C4.02604 10.3334 4.16667 10.1927 4.16667 10.0209ZM9.16667 10.0209V8.97921C9.16667 8.80733 9.02604 8.66671 8.85417 8.66671H7.8125C7.64063 8.66671 7.5 8.80733 7.5 8.97921V10.0209C7.5 10.1927 7.64063 10.3334 7.8125 10.3334H8.85417C9.02604 10.3334 9.16667 10.1927 9.16667 10.0209ZM11.6667 3.25004V12.4167C11.6667 13.1068 11.1068 13.6667 10.4167 13.6667H1.25C0.559896 13.6667 0 13.1068 0 12.4167V3.25004C0 2.55994 0.559896 2.00004 1.25 2.00004H2.5V0.645874C2.5 0.473999 2.64063 0.333374 2.8125 0.333374H3.85417C4.02604 0.333374 4.16667 0.473999 4.16667 0.645874V2.00004H7.5V0.645874C7.5 0.473999 7.64063 0.333374 7.8125 0.333374H8.85417C9.02604 0.333374 9.16667 0.473999 9.16667 0.645874V2.00004H10.4167C11.1068 2.00004 11.6667 2.55994 11.6667 3.25004ZM10.4167 12.2605V4.50004H1.25V12.2605C1.25 12.3464 1.32031 12.4167 1.40625 12.4167H10.2604C10.3464 12.4167 10.4167 12.3464 10.4167 12.2605Z"
      fill="white"
    />
  </svg>
);

Calendar.propTypes = {
  className: PropTypes.string,
};

export default Calendar;
