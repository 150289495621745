import classNames from "classnames";
import Link from "next/link";
import { useIntl } from "react-intl";
import LinesEllipsis from "react-lines-ellipsis";
import Tag from "base-components/Tag";
import { buildArticleURL } from "common/constants";
import { getFilePath } from "common/utils";
import { ClassNameArgument } from "types";
import { HomeArticles_homeArticles_data } from "types/graphql";
import styles from "./PinnedArticle.module.scss";

export type PinnedArticleProps = {
  article?: HomeArticles_homeArticles_data;
  className?: ClassNameArgument;
  size?: "small" | "large";
};

const PinnedArticle = ({
  article: homeArticle,
  className,
  size = "small",
}: PinnedArticleProps) => {
  const intl = useIntl();

  const { article } = homeArticle ?? {};

  return (
    <article className={classNames(styles.article, className)} data-size={size}>
      <figure className={styles.articleFigure}>
        {article ? (
          <Link href={`/article/${article.id}`} as={buildArticleURL(article)}>
            <a>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className={styles.articleFigureImage}
                alt={
                  article.title ??
                  intl.formatMessage({ defaultMessage: "Article" })
                }
                src={getFilePath(article.mainImage)}
              />
            </a>
          </Link>
        ) : (
          <div className={styles.articleFigureImage} />
        )}
        <figcaption className={styles.articleFigureCaption}>
          {article?.tag && (
            <Tag
              className={styles.articleFigureCaptionTagLink}
              href={{ pathname: "articles", query: { tag: article.tag } }}
            >
              {article.tag}
            </Tag>
          )}
          {article && (
            <Link href={`/article/${article.id}`}>
              <a className={styles.articleFigureCaptionLink}>
                <LinesEllipsis text={article.title ?? ""} maxLine={2} />
              </a>
            </Link>
          )}
        </figcaption>
      </figure>
    </article>
  );
};

export default PinnedArticle;
