import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styles from "./Carousel.module.scss";

const Carousel = ({ children, className = "", wrapperClassName }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);
  const [xTouch, setXTouch] = useState(null);
  const [yTouch, setYTouch] = useState(null);

  const minIndex = 0;
  const maxIndex = (children && children.length - 1) || 0;

  const handleTouchStart = (e) => {
    const firstTouch =
      (e.touches && e.touches[0]) || e.originalEvent.touches[0];
    setXDown(firstTouch.clientX);
    setYDown(firstTouch.clientY);
  };

  const scrollCarousel = (index, offset = 0) => {
    const parent = document.getElementById("carousel");
    const vWidth = window.innerWidth;

    const targetOffset = document.getElementById(
      `${styles.slide}-${index}`
    ).offsetLeft;
    const targetWidth = document
      .getElementById(`${styles.slide}-${index}`)
      .getBoundingClientRect().width;
    const maxOffset =
      targetWidth * children.length - (vWidth - targetWidth) + 16;

    let translateX = targetOffset - (vWidth - targetWidth) / 2 + 8;
    if (translateX > maxOffset) translateX = maxOffset;
    if (translateX < 0) translateX = 0;

    translateX += (targetWidth / 2) * offset;
    translateX = -translateX;
    parent.style.transform = `translateX(${translateX}px)`;

    if (offset === 0 && index !== activeIndex) {
      setActiveIndex(index);
    }
  };

  const handleTouchMove = (e) => {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        scrollCarousel(activeIndex, 1);
      } else {
        scrollCarousel(activeIndex, -1);
      }
    }
    setXTouch(xUp);
    setYTouch(yUp);
  };
  const handleTouchEnd = () => {
    if (!xDown || !yDown) {
      return;
    }

    const xDiff = xDown - xTouch;
    const yDiff = yDown - yTouch;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        if (activeIndex < maxIndex) {
          scrollCarousel(activeIndex + 1);
        } else {
          scrollCarousel(activeIndex);
        }
      } else if (activeIndex > minIndex) {
        scrollCarousel(activeIndex - 1);
      } else {
        scrollCarousel(activeIndex);
      }
    }
    setXDown(null);
    setYDown(null);
  };

  return (
    <div className={wrapperClassName}>
      <div className={styles.carouselWrap}>
        <div
          className={classNames(styles.carousel, className)}
          id="carousel"
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
        >
          {children &&
            children.map((child, index) => {
              if (!child) {
                return null;
              }
              return (
                <div
                  key={index}
                  className={classNames(
                    { [styles.active]: index === activeIndex },
                    styles.slide
                  )}
                  id={`${styles.slide}-${index}`}
                >
                  {child}
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.tabs}>
        {children &&
          children.map((child, index) => (
            <button
              key={`carousel-tab-${index}`}
              aria-label={`Select tab #${index}`}
              className={classNames(
                { [styles.activeTab]: index === activeIndex },
                styles.tab
              )}
              onClick={() => {
                scrollCarousel(index);
                setActiveIndex(index);
              }}
              type="button"
            />
          ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default Carousel;
