import { clamp, noop } from "lodash";
import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { PageInfo } from "types";

export type Paginator = {
  currentPage: number;
  currentPageNumbers: number[];
  currentPerPageOption: number;
  goToFirstPage: () => void;
  goToLastPage: () => void;
  goToNextPage: () => void;
  goToPage: (page: number) => void;
  goToPreviousPage: () => void;
  pagesCount: number;
  pageNumbers: number[];
  paginate: (children?: ReactNode[]) => ReactNode[];
  perPageOptions: number[];
  setCurrentPerPageOption: (option: number) => void;
  offset: number;
  limit: number;
};

export type PaginatorProviderProps = {
  defaultPage?: number;
  items?: ReactNode[];
  perPageOptions?: number[];
  pageButtonsMaxCount?: number;
  perPage?: number;
};

export type PaginationState<T> = [initialData: T[], pageInfo: PageInfo];

export const PaginatorContext = createContext<Paginator>({
  currentPage: -1,
  currentPageNumbers: [-1],
  currentPerPageOption: -1,
  goToFirstPage: () => noop(),
  goToLastPage: () => noop(),
  goToNextPage: () => noop(),
  goToPage: (page = 0) => noop(page),
  goToPreviousPage: () => noop(),
  pagesCount: -1,
  pageNumbers: [-1],
  paginate: (children = []) => (noop(children), []),
  perPageOptions: [-1],
  setCurrentPerPageOption: (option = 20) => noop(option),
  offset: -1,
  limit: -1,
});

export const usePaginatorContext = () => useContext(PaginatorContext);

export const usePaginationState = <DataType extends {}>(
  initialState: PaginationState<DataType> = [[], { totalCount: 0 }]
) => useState<PaginationState<DataType>>(initialState);

export const usePaginator = ({
  defaultPage = 0,
  itemsCount = 0,
  perPageOptions = [] as number[],
  pageButtonsMaxCount = 5,
  perPage = 20,
}): Paginator => {
  const [currentPerPageOption, setCurrentPerPageOption] = useState<number>(
    perPageOptions[0] ?? perPage
  );

  const [currentPage, setCurrentPage] = useState(defaultPage);

  const pagesCount = useMemo(
    () => Math.ceil(itemsCount / (currentPerPageOption || 1)),
    [itemsCount, currentPerPageOption]
  );

  const [offset, limit] = useMemo(
    () => [currentPage * currentPerPageOption, currentPerPageOption],
    [currentPage, currentPerPageOption]
  );

  const pageNumbers = useMemo(
    () =>
      Array(Math.max(pagesCount, 1))
        .fill(null)
        .map((_, index) => index),
    [pagesCount]
  );

  const currentPageFirstItemIndex = useMemo(
    () => currentPerPageOption * currentPage,
    [currentPerPageOption, currentPage]
  );

  const paginate = useCallback(
    (children?: ReactNode[]) =>
      [children]
        .filter(Boolean)
        .flat()
        .slice(
          currentPageFirstItemIndex,
          currentPageFirstItemIndex + currentPerPageOption
        ),
    [currentPageFirstItemIndex, currentPerPageOption]
  );

  const currentPageNumbers = useMemo(
    () =>
      pageNumbers.slice(
        Math.min(currentPage, Math.max(0, pagesCount - pageButtonsMaxCount)),
        currentPage + pageButtonsMaxCount
      ),
    [currentPage, pageButtonsMaxCount, pagesCount, pageNumbers]
  );

  const goToPage = useCallback(
    (page: number = currentPage) => {
      setCurrentPage(clamp(page, 0, pagesCount - 1));
    },
    [currentPage, pagesCount]
  );

  const goToFirstPage = useCallback(() => setCurrentPage(0), []);

  const goToLastPage = useCallback(
    () => setCurrentPage(pagesCount - 1),
    [pagesCount]
  );

  const goToPreviousPage = useCallback(() => {
    setCurrentPage(Math.max(currentPage - 1, 0));
  }, [currentPage]);

  const goToNextPage = useCallback(() => {
    setCurrentPage(Math.min(currentPage + 1, pagesCount - 1));
  }, [currentPage, pagesCount]);

  return {
    currentPage,
    currentPageNumbers,
    currentPerPageOption,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPage,
    goToPreviousPage,
    perPageOptions,
    pagesCount,
    pageNumbers,
    paginate,
    setCurrentPerPageOption,
    offset,
    limit,
  };
};

export const PaginatorProvider: FunctionComponent<PaginatorProviderProps> = ({
  children,
  defaultPage,
  items,
  perPageOptions,
  pageButtonsMaxCount,
  perPage,
}) => {
  const paginator = usePaginator({
    itemsCount: items?.length ?? 0,
    defaultPage,
    perPageOptions,
    pageButtonsMaxCount,
    perPage,
  });

  return (
    <PaginatorContext.Provider value={paginator}>
      {children}
    </PaginatorContext.Provider>
  );
};
