import Head from "next/head";
import Events from "components/Events/Events";
import PinnedArticles from "components/PinnedArticles";
import Articles from "pages/articles/index";

const Home = () => (
  <>
    <Head>
      <title>Y-zone</title>
    </Head>
    <PinnedArticles />
    <Events />
    <Articles defaultSubcategoryId={1} />
  </>
);

export default Home;
