import Link from "next/link";
import React from "react";
import Button from "base-components/Button";
import Carousel from "base-components/Carousel/Carousel";
import ContentWrapper from "base-components/ContentWrapper";
import useEmptyArray from "hooks/useEmptyArray";
import Calendar from "svg/Calendar";
import styles from "./EventsSkeleton.module.scss";

const EventsSkeleton = () => {
  const eventsArray = useEmptyArray(4);

  return (
    <ContentWrapper backgroundColor="cool-gray-950" className={styles.events}>
      <h1>
        <Calendar /> Nadchádzajúce udalosti
      </h1>
      <div className={styles.eventsWrap}>
        <Carousel>
          {eventsArray.map((_, index) => (
            <div key={`event-${index}`} className={styles.event}>
              <div className={styles.eventImageWrap} />
              <h2></h2>
              <div className={styles.eventMeta}>
                <span className={styles.eventDate}></span>
                <span className={styles.eventPlace}></span>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.eventsButton}>
        <Link href="/events/190007/msr-20">
          <a>
            <Button backgroundColor="cool-gray-600" htmlType="button">
              Všetky podujatia
            </Button>
          </a>
        </Link>
        <Link href="/tournaments">
          <a>
            <Button backgroundColor="cool-gray-600" htmlType="button">
              Všetky turnaje
            </Button>
          </a>
        </Link>
      </div>
    </ContentWrapper>
  );
};

export default EventsSkeleton;
