import classNames from "classnames";
import useEmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from "embla-carousel-react";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Button from "base-components/Button/Button";
import styles from "./EventsCarousel.module.scss";

type EventsCarouselProps = {
  options?: EmblaOptionsType;
  headerChildren?: ReactNode;
  heading?: ReactNode;
  name: string;
};

const EventsCarousel: FC<EventsCarouselProps> = ({
  children,
  options,
  headerChildren,
  heading,
  name,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const intl = useIntl();

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollTo = useCallback(
    (index: number) => {
      emblaApi && emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, [emblaApi, onInit, onSelect]);

  return (
    <section className={styles.carousel}>
      <header className={styles.carouselHeader}>
        {heading}
        {headerChildren}
        <nav
          className={styles.carouselButtons}
          aria-label={intl.formatMessage(
            { defaultMessage: "{name} carousel arrow navigation" },
            { name }
          )}
        >
          <Button
            onClick={scrollPrev}
            disabled={prevBtnDisabled}
            icon="angle-left"
            aria-label={intl.formatMessage(
              { defaultMessage: "Go to previous item of {name} carousel" },
              { name }
            )}
          />
          <Button
            onClick={scrollNext}
            disabled={nextBtnDisabled}
            icon="angle-right"
            aria-label={intl.formatMessage(
              { defaultMessage: "Go to next item of {name} carousel" },
              { name }
            )}
          />
        </nav>
      </header>
      <div className={styles.carouselViewport} ref={emblaRef}>
        <div className={styles.carouselViewportContainer}>{children}</div>
      </div>
      <nav
        className={styles.carouselDots}
        aria-label={intl.formatMessage(
          { defaultMessage: "{name} carousel dot navigation" },
          { name }
        )}
      >
        {scrollSnaps.map((_, index) => (
          <button
            aria-label={intl.formatMessage(
              { defaultMessage: "Go to carousel item {order}" },
              { order: index + 1 }
            )}
            key={`dot-number-${index}`}
            onClick={() => scrollTo(index)}
            className={classNames(
              styles.carouselDotsDot,
              index === selectedIndex ? styles.carouselDotsSelectedDot : ""
            )}
          />
        ))}
      </nav>
    </section>
  );
};

export default EventsCarousel;
