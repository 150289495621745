import { gql } from "@apollo/client";

export const HOME_EVENTS_QUERY = gql`
  query HomeEvents($where: HomeEventWhereInput, $offset: Int, $limit: Int) {
    homeEvents(where: $where, offset: $offset, limit: $limit) {
      data {
        id
        date
        place
        title
        url
        note
        image
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const HOME_EVENT_QUERY = gql`
  query HomeEvent($where: HomeEventWhereInput!) {
    homeEvent(where: $where) {
      id
      date
      place
      title
      url
      note
      image
    }
  }
`;

export const HOME_ARTICLES_QUERY = gql`
  query HomeArticles($where: HomeArticleWhereInput, $offset: Int, $limit: Int) {
    homeArticles(where: $where, offset: $offset, limit: $limit) {
      data {
        id
        article {
          id
          title
          tag
          mainImage
          tags
          subtitle
          publishedAt
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const GAME_GENERAL_RULE_QUERY = gql`
  query GameGeneralRule($where: GameGeneralRuleWhereInput!) {
    gameGeneralRule(where: $where) {
      gameId
      value
    }
  }
`;

export const UPSERT_GAME_GENERAL_RULE_QUERY = gql`
  mutation UpsertGameGeneralRule($data: GameGeneralRuleInput!) {
    upsertGameGeneralRule(data: $data) {
      value
    }
  }
`;

export const SET_HOME_ARTICLES_MUTATION = gql`
  mutation SetHomeArticles($articleIds: [Int!]!) {
    setHomeArticles(articleIds: $articleIds)
  }
`;

export const CREATE_HOME_EVENT_MUTATION = gql`
  mutation CreateHomeEvent($data: HomeEventInput!) {
    createHomeEvent(data: $data) {
      id
    }
  }
`;

export const UPDATE_HOME_EVENT_MUTATION = gql`
  mutation UpdateHomeEvent(
    $where: HomeEventWhereUniqueInput!
    $data: HomeEventInput!
  ) {
    updateHomeEvent(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_HOME_EVENT_MUTATION = gql`
  mutation DeleteHomeEvent($where: HomeEventWhereUniqueInput!) {
    deleteHomeEvent(where: $where) {
      id
    }
  }
`;
