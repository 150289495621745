import { useQuery } from "@apollo/client";
import classNames from "classnames";
import Await from "base-components/Await";
import ContentWrapper from "base-components/ContentWrapper";
import { HOME_ARTICLES_QUERY } from "graphql/home";
import { HomeArticles, HomeArticlesVariables } from "types/graphql";
import PinnedArticle from "./PinnedArticle";
import styles from "./PinnedArticles.module.scss";
import PinnedArticlesSkeleton from "./PinnedArticlesSkeleton";

const PinnedArticles = () => {
  // const isFirstLoad = useFirstLoad();
  // const videoRef = useRef<HTMLVideoElement>(null);

  // const [videoEnded, setVideoEnded] = useState(!isFirstLoad);

  // const [breakpointMatches] = useScreenMediaQuery("md");

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.defaultMuted = true;
  //   }
  // });

  // const onVideoEnded = () => {
  //   setVideoEnded(true);
  // };

  const { data, loading, error } = useQuery<
    HomeArticles,
    HomeArticlesVariables
  >(HOME_ARTICLES_QUERY, {
    variables: {
      limit: 3,
    },
    // onCompleted: () => {
    //   if (isFirstLoad) {
    //     videoRef.current?.play();
    //   }
    // },
  });

  const [firstArticle, secondArticle, thirdArticle] =
    data?.homeArticles?.data ?? [];

  return (
    <Await
      loading={loading}
      error={error}
      fallback={<PinnedArticlesSkeleton />}
    >
      <ContentWrapper
        contentClassName={styles.articlesWrapper}
        className={classNames(styles.pinnedArticles, {
          [styles.showBackground]: false, // videoEnded,
        })}
      >
        <section className={styles.articles}>
          {firstArticle && (
            <PinnedArticle article={firstArticle} size="large" />
          )}
          {secondArticle && thirdArticle && (
            <div className={styles.secondaryArticles}>
              {secondArticle && <PinnedArticle article={secondArticle} />}
              {thirdArticle && <PinnedArticle article={thirdArticle} />}
            </div>
          )}
          {/* {!videoEnded && isFirstLoad && (
            <video
              className={styles.articlesVideo}
              ref={videoRef}
              muted={true}
              autoPlay={true}
              playsInline={true}
              onEnded={onVideoEnded}
            >
              {breakpointMatches ? (
                <source src="/video/tipos/desktop.mp4" type="video/mp4" />
              ) : (
                <source src="/video/tipos/mobile.mp4" type="video/mp4" />
              )}
            </video>
          )} */}
        </section>
        {/* <AdBanner
          className={[
            styles.adBanner,
            {
              [styles.display]: true, // videoEnded,
            },
          ]}
        /> */}
      </ContentWrapper>
    </Await>
  );
};

export default PinnedArticles;
