import { captureException } from "@sentry/nextjs";
import { ReactNode, FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { LoadingIndicatorInline } from "base-components/LoadingIndicator";
import StatusMessage from "base-components/StatusMessage";
import useDelayedLoading from "hooks/useDelayedLoading";

export type AwaitProps = {
  loading: boolean;
  delay?: number;
  error?: any;
  fallback?: ReactNode;
  lockScroll?: boolean;
  errorFallback?: ReactNode | ((error: any) => ReactNode);
  forceFallback?: boolean;
};

const DEFAULT_ERROR_FALLBACK = (
  <StatusMessage autoClose={false} open type="error">
    <FormattedMessage defaultMessage="An error occurred. Try again later" />
  </StatusMessage>
);

const Await: FC<AwaitProps> = ({
  children,
  loading,
  error,
  delay = 300,
  lockScroll = false,
  fallback = <LoadingIndicatorInline />,
  errorFallback = DEFAULT_ERROR_FALLBACK,
  forceFallback = true,
}) => {
  const delayedLoading = useDelayedLoading(loading, delay, forceFallback);

  useEffect(() => {
    if (lockScroll) {
      document.body.style.setProperty(
        "overflow-y",
        loading ? "hidden" : "auto"
      );
    }
  }, [lockScroll, loading]);

  if (error) {
    console.error(error);
    captureException(error);

    return typeof errorFallback === "function"
      ? errorFallback(error)
      : errorFallback;
  }

  if (delayedLoading) {
    return fallback;
  }

  return children;
};

export default Await;
