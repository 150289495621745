import classNames from "classnames";
import { ClassNameArgument } from "types";
import styles from "./AdBanner.module.scss";

export type AdBannerProps = {
  className?: ClassNameArgument;
};

const AdBanner = ({ className }: AdBannerProps) => (
  <a
    className={classNames(className)}
    href="https://tipos.sk"
    rel="referrer"
    title="Tipos"
    target="__blank"
  >
    <picture>
      <source srcSet="/img/article_banner.png" media="(min-width: 48rem)" />
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        className={styles.banner}
        alt="Tipos"
        src="/img/article_banner_mobile.png"
      />
    </picture>
  </a>
);

export default AdBanner;
